import React, { FC } from 'react'
import cx from 'classnames'

import { PictureProps } from '../../atoms/Picture'
import { HtmlProps } from '../../atoms/Html'

import * as SC from './styled'

type Section = {
  title: string
  picture?: PictureProps
  text: HtmlProps
  pictureWidth?: 'full' | 'half'
}

export type TableIndexCheesesEditoProps = {
  className?: string
  sections: Section[]
}

const TableIndexCheesesEdito: FC<TableIndexCheesesEditoProps> = (props) => {
  const { className, sections } = props

  return (
    <SC.TableIndexCheesesEdito
      className={cx('TableIndexCheesesEdito', className)}
    >
      {sections.map((section, index) => (
        <SC.Section key={index}>
          <SC.Title>{section.title}</SC.Title>
          <SC.Content $width={section.pictureWidth ?? 'full'}>
            {section.picture && (
              <SC.Picture
                {...section.picture}
                $width={section.pictureWidth === 'full' ? '100%' : '50%'}
              />
            )}
            <SC.Text {...section.text} />
          </SC.Content>
        </SC.Section>
      ))}
    </SC.TableIndexCheesesEdito>
  )
}

export default TableIndexCheesesEdito
