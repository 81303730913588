import { TableIndexCheesesEditoProps } from './index'

const defaultProps: TableIndexCheesesEditoProps = {
  sections: [
    {
      title: 'Comment fabrique-t-on un fromage ?',
      picture: {
        alt: '',
        maxWidth: 1200,
        width: 1200,
        height: 480,
        withHD: false,
        images: [
          {
            src: '/static/assets/images/table-cheese-index-edito/how-mobile.webp',
            size: 600,
          },
          {
            src: '/static/assets/images/table-cheese-index-edito/how.webp',
            size: 1200,
          },
        ],
      },
      pictureWidth: 'full',
      text: {
        text: `
        La fabrication du fromage est un art qui mêle tradition et savoir-faire. Voici les principales étapes du processus :
        <br />
        <ul>
        <li><strong>La collecte du lait :</strong> Tout commence par le lait, qu'il soit de vache, de chèvre ou de brebis. La qualité de ce lait est primordiale pour obtenir un fromage de qualité.</li>
        <li><strong>Pasteurisation :</strong> Ensuite, le lait est souvent pasteurisé pour éliminer les bactéries nocives, sauf pour les fromages au lait cru qui conservent toutes leurs caractéristiques naturelles.</li>
        <li><strong>Ensemencement :</strong> Le lait est ensemencé avec des ferments lactiques qui vont transformer le lactose en acide lactique, apportant ainsi de l'acidité et des arômes spécifiques.</li>
        <li><strong>Caillage :</strong> On ajoute de la présure - ou un autre coagulant d’origine non animale - pour faire cailler le lait. Le caillé obtenu est découpé en petits morceaux pour favoriser l'élimination du lactosérum</li>
        <li><strong>Égouttage :</strong> Le caillé est égoutté pour enlever l'excès de lactosérum, un processus essentiel pour obtenir la bonne texture.</li>
        <li><strong>Moulage :</strong> Le caillé est moulé dans des formes spécifiques qui donneront au fromage sa forme finale.</li>
        <li><strong>Salage :</strong> Le fromage est salé pour le conserver et lui donner du goût. Cela peut se faire par salage en surface ou par immersion dans une saumure.</li>
        <li><strong>Affinage :</strong> Enfin, le fromage est affiné dans des caves à une température et une humidité contrôlées, pendant une période qui peut varier de quelques jours à plusieurs mois, voire des années, comme le parmesan.</li>
        </ul>
        </br>
        Chaque étape est cruciale et influence le goût, la texture et l'arôme du fromage.`,
      },
    },
    {
      title: 'Quels sont les différents types de fromage ?',
      picture: {
        alt: 'Lorem ipsum alt',
        maxWidth: 1200,
        width: 1200,
        height: 480,
        withHD: false,
        images: [
          {
            src: '/static/assets/images/table-cheese-index-edito/what-mobile.webp',
            size: 600,
          },
          {
            src: '/static/assets/images/table-cheese-index-edito/what.webp',
            size: 1200,
          },
        ],
      },
      pictureWidth: 'full',
      text: {
        text: `
        Les fromages se distinguent par leurs caractéristiques uniques, notamment en fonction de leur pâte, du lait utilisé ou de leur texture. Explorons ces divers types pour mieux comprendre leurs spécificités et leurs usages.
        <br />
        <h3>Par type de pâte</h3>
        <ul>
        <li><strong>Pâtes fraîches :</strong> Ces fromages ne subissent aucun affinage et sont consommés rapidement après leur fabrication. Ils sont doux, crémeux et souvent utilisés dans les salades ou en tartinade. Exemple : la Feta AOP</li>
        <li><strong>Pâtes molles à croûte fleurie :</strong> Recouverts d'une fine couche de moisissure blanche, ces fromages ont une texture crémeuse et une saveur délicate.</li>
        <li><strong>Pâtes molles à croûte lavée :</strong> Ces fromages sont lavés régulièrement avec une saumure ou une solution alcoolisée, ce qui leur donne une croûte humide et une saveur puissante.</li>
        <li><strong>Pâtes persillées :</strong> Appelés aussi "bleus", ces fromages sont marbrés de moisissures internes qui leur donnent un goût intense et unique.</li>
        <li><strong>Pâtes pressées cuites :</strong> Chauffés avant d'être pressés, ces fromages ont une texture ferme et granuleuse. Ils sont parfaits pour les râpés ou les fondues.</li>
        <li><strong>Pâtes pressées non cuites :</strong> Pressés mais non chauffés, ces fromages conservent une texture souple tout en développant des arômes riches.</li>
        </ul>
        </br>
        <h3>Par type de lait</h3>
        <ul>
        <li><strong>Lait de vache :</strong> Le plus courant pour la fabrication de fromages, il produit une gamme de textures et de saveurs, du doux brie au fort roquefort.</li>
        <li><strong>Lait de chèvre :</strong> Apprécié pour son goût légèrement acidulé, il donne des fromages de chèvre souvent plus crémeux et parfumés, comme le crottin de Chavignol.</li>
        <li><strong>Lait de brebis :</strong> Utilisé pour des fromages de brebis au goût plus riche et plus gras, comme le roquefort ou le manchego.</li>
        <li><strong>Lait de bufflonne :</strong> Principalement pour la mozzarella, ce lait produit des fromages doux et crémeux, parfaits pour des préparations fraîches.</li>
        </ul>
        `,
      },
    },
    {
      title: 'Quels sont les fromages préférés des Français ?',
      picture: {
        alt: 'Lorem ipsum alt',
        maxWidth: 1200,
        width: 1200,
        height: 480,
        withHD: false,
        images: [
          {
            src: '/static/assets/images/table-cheese-index-edito/prefered-mobile.webp',
            size: 600,
          },
          {
            src: '/static/assets/images/table-cheese-index-edito/prefered.webp',
            size: 1200,
          },
        ],
      },
      pictureWidth: 'half',
      text: {
        text: `
Les Français adorent les fromages et les chiffres le prouvent. Chaque année, vous en consommez en moyenne 27 kg (2e place européenne, juste derrière la Grèce), ce qui représente un total de 860 000 tonnes de fromage par an. Un Français sur trois en mange même quotidiennement. Et à la question de savoir quels sont les produits du terroir qui vous manquent le plus quand vous partez à l’étranger, vous répondez en premier la baguette de pain et tout de suite après… le fromage.<br/><br/>
85 % des fromages achetés dans le pays sont au lait de vache. Viennent ensuite les fromages de chèvre qui représentent 9 % du marché, puis les fromages de brebis avec 6 %. Selon <a href="https://www.franceagrimer.fr/fam/content/download/71701/document/STA-LAI-Consommation%20de%20produits%20laitiers%20en%202022.pdf?version=1" target="_blank">les chiffres de FranceAgriMer</a>, l’emmental, le comté et le fromage à raclette dominent le podium des fromages les plus consommés, devant la mozzarella, les bûches de chèvre affinées, le camembert, le brie, le parmesan, le coulommiers et le roquefort.<br/><br/>
En 2024, un concours réalisé par la radio RTL et rassemblant plus de 17 000 votes a enfin élu le maroilles fromage préféré des Français. Avec 37% des voix, l’emblème du Nord de la France a devancé le Roquefort (20% des votes) et le Sainte-Maure de Touraine (16%).
        `,
      },
    },
    {
      title: 'Top 10 des fromages les plus connus des Français',
      text: {
        text: `
Les Français sont considérés comme les plus connaisseurs de fromage au monde. Nous sommes en effet les seuls sur la planète capables de citer spontanément, en moyenne, plus de 6 fromages différents. Le camembert reste le fromage le plus célèbre. C’est en tout cas ce qui ressort des chiffres d’une étude menée pour le CNIEL (Centre National Interprofessionnel de l’Economie Laitière). Voici le Top 10. <br/>
<ol>
<li>Camembert</li>
<li>Bûche de chèvre</li>
<li>Comté</li>
<li>Gruyère</li>
<li>Brie</li>
<li>Coulommiers</li>
<li>Roquefort</li>
<li>Tomme Basque</li>
<li>Reblochon</li>
<li>Saint Nectaire</li>
</ol>
        `,
      },
    },
    {
      title: 'Comment les Français consomment-ils le fromage ?',
      picture: {
        alt: 'Lorem ipsum alt',
        maxWidth: 1200,
        width: 1200,
        height: 480,
        withHD: false,
        images: [
          {
            src: '/static/assets/images/table-cheese-index-edito/eat-mobile.webp',
            size: 600,
          },
          {
            src: '/static/assets/images/table-cheese-index-edito/eat.webp',
            size: 1200,
          },
        ],
      },
      pictureWidth: 'half',
      text: {
        text: `
Les Français consomment le fromage de manière variée et inventive. Ils en ont fait un pilier de leur alimentation quotidienne. 87 % des Français l’utilisent comme ingrédient dans des plats chauds, comme les gratins, les quiches, ou les raclettes en hiver. Mais les ¾ de la population l’apprécient aussi dans des plats froids, comme les salades. Le traditionnel plateau de fromage reste un incontournable, surtout pour les générations plus âgées. 73 % des Français le dégustent ainsi.<br/></br/>
Pour les jeunes adultes, le fromage s’intègre de plus en plus entre les repas. 41 % des Français optent pour un morceau de fromage comme en-cas, et 35 % l’apprécient à l'apéritif, sur une tartine ou en association avec des fruits secs et des olives.<br/></br/>
Le dîner reste néanmoins le moment privilégié pour consommer du fromage : Les deux-tiers d’entre vous le choisissent pour conclure leur repas du soir. Le déjeuner suit avec 48 %, mais la tendance est à l’intégration du fromage à d’autres moments de la journée, représentant 19 % des occasions de consommation. Que ce soit lors d’un pique-nique, d’un brunch, ou d’un goûter gourmand, le fromage est toujours le bienvenu.
        `,
      },
    },
  ],
}

export const TableIndexCheesesEditoMocks: TableIndexCheesesEditoProps = {
  ...defaultProps,
}
