import styled, { css } from 'styled-components'

import _Picture from '../../atoms/Picture'
import Html from '../../atoms/Html'
import { mq, sizes } from '../../../theme'

export const TableIndexCheesesEdito = styled.div`
  background-color: ${({ theme }) => theme.colors.pureWhite};
  border-radius: 3rem;
  padding: 3rem 1.5rem;

  @media ${mq(sizes.tablet)} {
    padding: 4rem;
  }
`

export const Section = styled.div`
  &:not(:last-child) {
    margin-bottom: 6rem;
  }
`

export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.titleH3}
  color: ${({ theme }) => theme.colors.arapawa};
  text-align: center;
  margin: 0 0 4rem 0;
  @media ${mq(sizes.tablet)} {
  }
`

export const Content = styled.div<{ $width: string }>`
  display: flex;
  flex-direction: column;

  @media ${mq(sizes.tablet)} {
    flex-direction: ${({ $width }) =>
      $width === 'full' ? 'column' : 'row-reverse'};

    ${({ $width }) =>
      $width === 'half' &&
      css`
        gap: 4rem;
        & > * {
          flex: 1;
          margin-top: 0 !important;
        }
      `}
  }
`

export const Picture = styled(_Picture)<{ $width: string }>`
  display: block;
  margin-bottom: 4rem;
  @media ${mq(sizes.tablet)} {
    width: ${({ $width }) => $width};
  }
`

export const Text = styled(Html)`
  & ul {
    padding-left: 2rem;
  }

  & li {
    margin-bottom: 1rem;
  }

  & h3 {
    color: ${({ theme }) => theme.colors.ruby};
    ${({ theme }) => theme.textStyles.textXLarge}
    font-weight: 700;
  }

  & ol {
    padding-left: 2rem;

    & li {
      ${({ theme }) => theme.textStyles.textMediumStrong}
      margin-bottom: 0;
    }
  }
`
